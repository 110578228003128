export default {
  searchAllRecordsLoadingState(state) {
    return state.loading;
  },
  searchAllRecordsListState(state) {
    return state.list || [];
  },
  searchAllRecordsMetaState(state) {
    return state.meta || {};
  },
  searchAllRecordsFormState(state) {
    return state.form;
  },
  searchAllRecordsOptionsState(state) {
    return state.options || {};
  },
  searchAllRecordsOptionsLoadingState(state) {
    return state.optionsLoading;
  },

  aiSearchQueryState(state) {
    return state.aiSearchQuery;
  },
  aiSearchPromptLoadingState(state) {
    return state.aiSearchPromptLoading;
  },
  aiSearchFollowUpLoadingState(state) {
    return state.aiSearchFollowUpLoading;
  },
};
