export default {
  setSearchAllRecordsLoadingState(state, loading) {
    state.loading = loading;
  },
  setSearchAllRecordsListState(state, list) {
    state.list = list;
  },
  setSearchAllRecordsMetaState(state, meta) {
    state.meta = meta;
  },
  setSearchAllRecordsOptionsState(state, options) {
    state.options = options;
  },
  setSearchAllRecordsOptionsLoadingState(state, loading) {
    state.optionsLoading = loading;
  },
  mutateSearchAllRecordsFormState(state, data) {
    state.form = {...state.form, ...data};
  },
  clearSearchAllRecordsFormState(state, data) {
    const form = {};
    for (let key of Object.keys(state.form)) {
      form[key] = data && data[key] ? data[key] : null;
    }
    state.form = form;
  },

  setAiSearchQueryState(state, query) {
    state.aiSearchQuery = query;
  },
  setAiSearchPromptLoadingState(state, loading) {
    state.aiSearchPromptLoading = loading;
  },
  setAiSearchFollowUpLoadingState(state, loading) {
    state.aiSearchFollowUpLoading = loading;
  },
};
